.button {
	width: fit-content;
	display: flex;
	height: 50px;
	background-color: var(--blue-primary);
	border-radius: 10px;
	border: none;
	color: white;
	font-weight: bold;
	align-self: center;
	align-items: center;
	justify-content: center;
	padding: 10px 15px;
	cursor: pointer;
}
