.intro {
	height: 100vh;
	width: 100%;
	position: relative;
}

.intro::after {
	content: ' ';
	height: 100%;
	width: 100%;
	background: linear-gradient(
		180deg,
		transparent 65%,
		rgba(255, 255, 255, 1)
	);
	display: block;
	position: absolute;
	top: 0;
	right: 0;
}

.image {
	height: 100vh;
	width: 100%;
	object-fit: cover;
}

.caption {
	width: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}

.title {
	font-size: 60px;
	text-align: start;
	color: white;
	margin-top: 0;
	margin-bottom: 30px;
}

.subtitle {
	font-size: 24px;
	line-height: 40px;
	text-align: start;
	color: white;
	margin-top: 0;
	margin-bottom: 30px;
}

.wrapper {
	position: relative;
	height: 100vh;
	width: 100%;
	background: linear-gradient(
			to top,
			rgba(253, 253, 253, 0) 0%,
			rgba(255, 255, 255, 1) 100%
		),
		url('../../assets/images/wallpaper.jpg');
	overflow: hidden;
}

.content {
	width: 100%;

	height: 70vh;
	margin: 0 auto 50px auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: scroll;
	overflow-x: hidden;
}
.content::-webkit-scrollbar {
	display: none;
}
.description {
	width: 100%;
}

.form__wrapper {
	margin: 100px auto 50px auto;
	width: 60%;
}

@media (max-width: 992px) {
	.form__wrapper {
		margin: 100px auto 50px auto;
		width: 95%;
	}
}

@media (max-width: 768px) {
	.title {
		font-size: 48px;
	}
}

@media (max-width: 576px) {
	.title {
		font-size: 38px;
		margin-bottom: 20px;
	}

	.subtitle {
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 20px;
	}
}
