.wrapper {
	background-color: white;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 20;
}

.image {
	width: 40%;
	object-fit: contain;
	min-width: 220px;
}
