.icon {
	cursor: pointer;
}

.fill {
	fill: #ffffff;
	transition: fill 0.3s linear;
}

.fill:hover {
	fill: var(--blue-primary);
}
