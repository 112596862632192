@import './assets/fonts/fontc.scss';
:root {
	--blue-primary: #62a1d8;
}

body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI',
		'Kablammo', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
}

.center img {
	transition: all 300ms ease;
}

.center .slick-center img {
	-moz-transform: scale(1.3);
	-ms-transform: scale(1.3);
	-o-transform: scale(1.3);
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}

.slick-slider .slick-dots {
	bottom: -55px;
}

.slick-slider .slick-dots li button:before {
	font-size: 10px;
}

.slick-slider .slick-dots li.slick-active button:before {
	color: rgb(45, 139, 220);
}
