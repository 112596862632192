.container {
	position: relative;
	width: 100%;
	height: 100%;
}
.textarea {
	font-family: 'Roboto', sans-serif;
	display: block;
	box-sizing: border-box;
	border: none;
	border: 1px solid var(--blue-primary);
	border-radius: 4px;
	width: 100%;
	padding: 10px 35px 10px 10px;
	outline: none;
	height: 80px;
	font-size: 16px;
}

.textarea::placeholder {
	font-size: 16px;
}

.textarea:focus {
	border: 2px solid var(--blue-primary);
}

.textarea:hover {
	border: 2px solid var(--blue-primary);
}

.error {
	color: red;
	position: absolute;
	font-weight: bold;
	top: 11px;
	right: 10px;
}
