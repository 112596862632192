.title {
	margin: 60px auto;
	font-size: 44px;
	font-weight: bold;
}

@media (max-width: 768px) {
	.title {
		font-size: 38px;
	}
}

@media (max-width: 576px) {
	.title {
		margin: 30px auto;
		font-size: 28px;
	}
}
