.gallery {
	width: 100vw;
}

.gallery__image {
	width: 33vw;
	height: 20vw;
	object-fit: cover;
	margin: 20px;
}

@media (max-width: 768px) {
	.gallery__image {
		height: 45vw;
	}
}
