.container {
	position: relative;
	width: 100%;
}

.input {
	display: block;
	height: 38px;
	border: 1px solid var(--blue-primary);
	width: 100%;
	padding-left: 10px;
	padding-right: 35px;
	outline: none;
	transition: all 0.2s linear;
	box-sizing: border-box;
	font-size: 16px;
}

.input:focus {
	border: 2px solid var(--blue-primary);
}

.input:hover {
	border: 2px solid var(--blue-primary);
}

.error {
	color: red;
	position: absolute;
	font-weight: bold;
	top: 10px;
	right: 10px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input[type='number'],
input[type='number']:hover,
input[type='number']:focus {
	appearance: none;
	-moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}
