.container {
	position: relative;
	width: 100%;
}

.input {
	display: block;
	height: 38px;
	border: 1px solid var(--blue-primary);
	width: 100%;
	padding-left: 10px;
	padding-right: 35px;
	outline: none;
	transition: all 0.2s linear;
	box-sizing: border-box;
	font-size: 16px;
}

.input:focus {
	border: 2px solid var(--blue-primary);
}

.input:hover {
	border: 2px solid var(--blue-primary);
}

.error {
	color: red;
	position: absolute;
	font-weight: bold;
	top: 10px;
	right: 10px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input[type='number'],
input[type='number']:hover,
input[type='number']:focus {
	appearance: none;
	-moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

.list {
	list-style: none;
	padding: 0;
	margin: 0;
	position: absolute;
	z-index: 2;
	background-color: white;
	top: 45px;
	right: 0;
	width: 100%;
}

.list_default {
	height: 0;
	visibility: hidden;
}

.list_active {
	visibility: visible;

	border: 1px solid #ccc;
	border-radius: 4px;
	height: 160px;
	overflow-y: scroll;
}

.list__item {
	cursor: pointer;
	height: 40px;
	display: flex;
	align-items: center;
	padding-left: 15px;
	padding-right: 15px;
	font-size: 14px;
}

.list__item:hover {
	transition: background-color 0.3s linear;
	background-color: var(--blue-primary);
	color: white;
	border-radius: 4px;
}

@media (max-width: 768px) {
	.list_active {
		height: 280px;
	}
}
