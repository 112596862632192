.wrapper {
	position: relative;
}

.image {
	width: 100%;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	object-fit: cover;
}

.title {
	font-size: 68px;
	color: white;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -60%);
	z-index: 1;
}

.title_large {
	font-size: 84px;
}

@media (max-width: 768px) {
	.title {
		font-size: 54px;
	}

	.title_large {
		font-size: 68px;
	}
}

@media (max-width: 576px) {
	.title {
		font-size: 44px;
	}

	.title_large {
		font-size: 54px;
	}
}
