.wrapper {
	width: 31%;
	height: 45vh;
	background-size: cover;
	cursor: pointer;
	border-radius: 10px;
	position: relative;
	margin: auto;
}

.wrapper:hover {
	transition: all 0.3s linear;
	scale: 1.02;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	background-color: transparent;
	transition: all 0.3s linear;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
}

.overlay:hover {
	background-color: rgba(0, 0, 0, 0.4);
}

.title {
	font-size: 22px;
	font-weight: bold;
	width: 100%;
	color: white;
	border-bottom: 1px solid white;
}

.details {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border-bottom: 1px solid white;
	margin-top: 0;
	margin-bottom: 40px;
}

.details__text {
	color: white;
	font-size: 18px;
	font-weight: normal;
	margin: 0;
	padding: 0 15px;
}

@media (max-width: 992px) {
	.wrapper {
		width: 47%;
	}
}

@media (max-width: 768px) {
	.wrapper {
		height: 40vh;
	}
}

@media (max-width: 576px) {
	.wrapper {
		width: 90%;
		height: 350px;
	}
}
