.wrapper {
	width: 100%;
	height: fit-content;
	margin: auto;
	background-image: url(../../assets/images/chum.png);
	background-size: 60%;
	background-repeat: no-repeat;
	background-position: 130% 60%;
	background-color: rgb(255, 255, 255);
	position: relative;
	overflow: hidden;
	border-radius: 15px;
	padding: 0 20px 25px 20px;
	-webkit-box-shadow: 0px -8px 33px 37px rgba(98, 161, 216, 0.2);
	-moz-box-shadow: 0px -8px 33px 37px rgba(98, 161, 216, 0.2);
	box-shadow: 0px -8px 33px 8px rgba(194 200 205 / 20%);
	box-sizing: border-box;
}

.flexBox {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	gap: 30px;
	margin-bottom: 10px;
}

.title {
	color: var(--blue-primary);
	font-size: 24px;
	padding: 20px;
	margin: 0;
	text-shadow:
		-1px 0 rgba(70, 70, 71, 0.595),
		0 1px rgba(70, 70, 71, 0.595),
		1px 0 rgba(70, 70, 71, 0.595),
		0 -1px rgba(70, 70, 71, 0.595);
}

.inputs {
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 10px;
}

.chum {
	height: 130%;
	width: fit-content;
	position: absolute;
	top: -18%;
	right: -17%;
}

@media (max-width: 992px) {
	.wrapper {
		width: 95%;
	}
}

@media (max-width: 768px) {
	.wrapper {
		height: 538px;
		background-position: 160% 100%;
		background-size: 80%;
	}
	.inputs {
		width: 95%;
	}
}
