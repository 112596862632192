.links {
	width: fit-content;
	padding: 30px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	border-radius: 10px;
	background-color: #8bd0f7;
	list-style: none;
	position: absolute;
	top: 0;
	right: 20px;
	transform-origin: right top;
	box-sizing: border-box;
	margin: 0;
}

.icon {
	display: block;
	position: relative;
	z-index: 6;
	margin-right: 30px;
	margin-top: -25px;
}

.link {
	height: 40px;
	font-size: 18px;
	font-weight: bold;
	text-decoration: none;
	margin: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	border-bottom: 1px solid whitesmoke;
	padding-bottom: 5px;
}

.link_default {
	color: white;
}

.link_active {
	color: var(--blue-primary);
	background-color: #8bd0f7;
}

@media (max-width: 578px) {
	.links {
		width: 100vw;
		right: 0;
	}

	.icon {
		margin-right: 15px;
	}
}
