.wrapper {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	margin-left: auto;
	margin-right: auto;
	height: 40vh;
	width: 100%;
	background: transparent;
	overflow: hidden;
}

.slider__background {
	width: 100%;
	height: 40vh;
	background: url(../../assets/images/treesbackground.png);
	background-size: 30%;
	background-repeat: repeat-x;
	background-position: bottom;
}

.slider__image {
	width: 70%;
	height: 30vh;
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	margin-left: auto;
	margin-right: auto;
	z-index: 2;
}

@media (max-width: 1400px) {
	.slider__image {
		width: 90%;
	}
}
@media (max-width: 1200px) {
	.slider__background {
		background-size: 50%;
	}
}

@media (max-width: 992px) {
	.slider__image {
		bottom: -8vh;
	}
}

@media (max-width: 768px) {
	.slider__background {
		background-size: 70%;
	}
	.slider__image {
		bottom: -12vh;
	}
}

@media (max-width: 576px) {
	.wrapper {
		height: 30vh;
		padding-bottom: 20px;
	}

	.slider__background {
		height: 30vh;
	}
	
	.slider__image {
		width: 100%;
		bottom: -10vh;
	}
}
