.wrapper {
	width: 60%;
	height: fit-content;
	margin: auto;
	background-image: url(../../assets/images/chum.png);
	background-size: 60%;
	background-repeat: no-repeat;
	background-position: 130% 60%;
	background-color: rgb(255, 255, 255);
	position: relative;
	overflow: hidden;
	border-radius: 15px;
	padding: 0 20px 45px 20px;
	box-sizing: border-box;
}

.title {
	color: var(--blue-primary);
	font-size: 24px;
	margin: 25px;
	text-shadow:
		-1px 0 rgba(70, 70, 71, 0.595),
		0 1px rgba(70, 70, 71, 0.595),
		1px 0 rgba(70, 70, 71, 0.595),
		0 -1px rgba(70, 70, 71, 0.595);
	z-index: 3;
	position: relative;
}

.inputs {
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 10px;
}

.chum {
	height: 130%;
	width: fit-content;
	position: absolute;
	top: -18%;
	right: -17%;
}

@media (max-width: 992px) {
	.wrapper {
		width: 95%;
	}
}

@media (max-width: 768px) {
	.wrapper {
		height: 538px;
		background-position: 80% 100%;
		background-size: 100%;
		margin-top: 0;
	}

	.inputs {
		width: 95%;
	}
}

@media (max-width: 576px) {
	.title {
		margin-top: 15px;
		margin-bottom: 10px;
	}
}
